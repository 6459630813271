<template>
  <div class="parallax" data-background="/static/images/parallax-banner.png">
    <!-- Infobox -->
    <div class="text-content white-font block-space">
      <div class="row">
        <div class="col-lg-10 col-sm-10 mx-auto">
          <v-row class="mx-0 flex-column">
            <h4 class="mx-auto text-center">{{ title }}</h4>
            <p class="mx-auto text-center">{{ desc }}</p>
            <a class="btn btn-primary outlined mx-auto">{{ button }}</a>
          </v-row>
        </div>
      </div>
    </div>
    <!-- Infobox / End -->
  </div>
</template>

<script>
  export default {
    props: ['title', 'desc', 'button'],
  }
</script>
