<template>
	<div>
		<v-row class="my-5" v-if="loading">
			<v-skeleton-loader
				class="mx-auto my-2 col-6 col-md-3 col-lg-4"
				max-width="300"
				type="image"
			></v-skeleton-loader>
			<v-skeleton-loader
				class="mx-auto my-2 col-6 col-md-3 col-lg-4"
				max-width="300"
				type="image"
			></v-skeleton-loader>
		</v-row>
		<v-row no-gutters class="p-4">
			<div class="col-12">
				<h3 class="px-4">{{ title }}</h3>
			</div>
			<div class="col-12 col-md-6 shadow-md" v-for="(item, id) in items" :key="id">
				<v-row class="m-3 bg-white justify-content-center shadow-lg">
					<picture
						class="col-9 col-sm-5 col-lg-4 d-flex align-items-center justify-content-center p-0 listing-item-image"
					>
						<v-img
							aspect-ratio="1"
							v-if="item.image && item.image.route != ''"
							:src="item.image.route"
							class="img-fluid"
						/>
					</picture>
					<div class="col-12 col-sm-7">
						<h5 class="text-primary">{{ item.name + ' ' + item.lastname }}</h5>
						<p class="mb-0">{{ item.position }}</p>
						<v-row no-gutters class="mb-2 flex-nowrap">
							<v-icon>mdi-email</v-icon>
							<a
								class="text-link pl-2"
								:href="`mailto:${extractContent(item.email)} `"
								v-html="`${extractContent(item.email)}`"
							>
							</a>
						</v-row>
						<v-row no-gutters>
							<img
								class="country__flag--directors"
								style="border-radius: 50%"
								:src="item.countries[0].flag"
								:alt="`País al que representa:' ${item.countries[0].name}`"
							/>
						</v-row>
					</div>
				</v-row>
			</div>
		</v-row>
	</div>
</template>

<script>
	export default {
		name: 'Directors',
		created() {},
		data() {
			return {}
		},
		props: {
			title: String,
			items: { type: Array },
			loading: { type: Boolean, required: false },
		},
		methods: {
			extractContent(html) {
				return new DOMParser().parseFromString(html, 'text/html').documentElement
					.textContent
			},
		},
	}
</script>

<style lang="scss" scoped>
	.country__flag--directors {
		width: 35px;
		height: 35px;
	}
	.text-link {
		color: inherit;
		word-break: break-word;
	}
	.img-fluid {
		max-height: 200px;
		max-width: 200px;
	}
</style>
